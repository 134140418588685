import React, { useState, useEffect, useRef } from "react"
import {Helmet} from "react-helmet";
import { Container, Row, Col, Card, Badge } from "react-bootstrap"
import firebase from "gatsby-plugin-firebase"

import { dateFormat } from "../helpers"
import KrugerProductsLogo from "../images/kruger_products-logo.svg"

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import "../styles/index.scss"


const ScreenPage = ({data}) => {
  const maxItems = 5;
  const cardHolder = useRef();
  const [firebaseData, setFirebaseData] = useState(null);
  const [currentDate, setCurrentDate] = useState('');
  const [headCount, setHeadCount] = useState(0);
  const [total, setTotal] = useState(0);

  const [visitors, setVisitors] = useState([]);
  const [sortedVisitors, setSortedVisitors] = useState([]);

  useEffect(()=>{

    setTotal(0);
    
    data.allContentfulVisitor.edges.forEach( visitor => {
      if ( visitor.node.active ) {
        setTotal(count => count + 1);
      }
    });


    firebase
      .database()
      .ref("/visitors")
      .on("value", snapshot => {
        //refresh the date when the data was updated
        
        var dt = new Date();
        // console.log(dt);

        dt.setTime(dt.getTime()+dt.getTimezoneOffset()*60*1000);
        // console.log(dt); // Gives Tue Mar 22 2016 04:00:00 GMT+0530 (IST)

        let offset = -300; //Timezone offset for EST in minutes.
        let estDate = new Date(dt.getTime() + offset*60*1000);
        // console.log(estDate); //Gives Mon Mar 21 2016 23:00:00 GMT+0530 (IST)

        const date = dateFormat.format(new Date(estDate))
        setCurrentDate( date );

        //refresh the headcount, set to zero first as state holds the value
        setVisitors([]);
        setHeadCount(0);


        const snapshotValue = snapshot.val();
        
        setFirebaseData(snapshot.val())

        if (snapshotValue) {
          Object.keys( snapshotValue ).forEach(key => {
            console.log( snapshotValue[key].status );
            if (snapshotValue[key].status === "passed"){
              const visitor = data.allContentfulVisitor.edges.find(obj => obj.node.id === key)
              setVisitors(prevState => 
                [...prevState, visitor.node]
              );
              setHeadCount(count => count + 1);
            }
          })
        }

      })

  }, [])

  useEffect(()=>{
    const sortVisitors = [...visitors].sort((a,b)=>{
      return a.lastName.localeCompare(b.lastName);
    });

    setSortedVisitors(sortVisitors);

  }, [visitors])

  const VisitorCard = ({visitor}) => {
    const height =  (cardHolder.current.clientHeight / maxItems) - 20;
    return (
      <Card 
        className="visitor-card bg-utility mb-3"
        style={{height: height + 'px'}}>
        <Card.Body className="text-dark d-flex align-items-center">
          <h2 className="mb-0 d-flex w-100 justify-content-end align-items-center">
            <div className="mb-0">{ visitor.firstName } { visitor.lastName }</div>
            <Badge className="ml-auto circle" variant="primary">{ visitor.department.zone }</Badge>
          </h2>
        </Card.Body>
      </Card>
    )
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kruger Greenlight - Lobby</title>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#00a300" />
        <meta name="theme-color" content="#ffffff" />
        
      </Helmet>
      <Container fluid className="vh-100 container-reset">
        <Row className="h-100">
          <Col md="4" className="h-100 bg-light p-5">
            <img src={KrugerProductsLogo} className="brand mb-4"/>
            <h1 className="text-dark">Welcome back.</h1>
            <p className="lead text-dark mb-5">Your health and safety is our #1 priority. Please be sure to follow all safety measures and have a great day.</p>
            <h4 className="text-dark mb-3">Today’s headcount</h4>
            <Card className="p-4 bg-o-45">
              <CircularProgressbar 
                value={ (headCount / total) } 
                maxValue={1} 
                text={`${ headCount }`} 
                styles={buildStyles({
                  textSize: '24px'
                })}
              />
            </Card>
          </Col>
          <Col className="p-5 h-100 d-flex flex-column">
            <p className="text-secondary h4 mb-0">{ currentDate }</p>
            <h1 className="mb-4 text-dark">Who's in the office today</h1>
            <Card className="bg-dark text-light mb-4">
              <Card.Body className="d-flex justify-content-between">
                <h2 className="h4 mb-0">Name</h2>
                <h2 className="h4 mb-0">Zone</h2>
              </Card.Body>
            </Card>
            <div ref={cardHolder} className={`card-holder h-100 ${headCount > 5 ? 'animate-up' : ''}`}>
              <div className={`cards`}>

                { sortedVisitors.map((visitor, index) => {
                  return (
                    <VisitorCard key={index} visitor={visitor}/>
                  )
                })}

              </div>
              { headCount > 5 ? <div className="cards">
                { sortedVisitors.map((visitor, index) => {
                  return (
                    <VisitorCard key={index} visitor={visitor}/>
                  )
                })}
                </div> : ''}
            </div>
            
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ScreenPage

export const query = graphql`
query {
  allContentfulVisitor {
    edges {
      node {
        id: contentful_id
        firstName
        lastName
        email
        department {
          departmentName
          zone
        }
        active
      }
    }
  }
}
`